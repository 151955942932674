:root {
  --bn-brand-color: #6236ff;
  --bn-brand-color-dark: #4631bb;
  --bn-white: #ffffff;
  --bn-brand-2: #000000;
  --bn-dark-1: #151515;
  --bn-dark-2: #2b2b2b;
  --bn-border-color-1: rgba(151, 151, 151, 0.2);
  --bn-font-1: #ffffff;
  --bn-font-2: rgba(255, 255, 255, 0.4);
  --bn-light: rgba(255, 255, 255, 0.2);
  --bn-error: #9b2f2f;
  --bn-scrollbar: #505050;
  --bn-scrollbar-hover: #333;
}

.seat-selected-span svg path {
  fill: var(--bn-brand-color);
}

.App {
  /* text-align: center; */
  /* padding: 0 15px; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--bn-font-1);
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.todo-list {
  background: #e8e8e8;
  border-radius: 4px;
  padding: 5px;
  max-width: 500px;
  margin: 0 auto;
}

.todo {
  background: var(--bn-white);
  -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  padding: 3px 10px;
  font-size: 12px;
  margin-bottom: 6px;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pb-10 {
  padding-bottom: 10px;
}

.route-wrapper {
  position: relative;
}

.route-wrapper > div {
  /* position: absolute; */
}

.overflow-x {
  overflow-x: auto;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.w-33 {
  width: 33%;
}

.w-67 {
  width: 67%;
}

.div-right {
  position: absolute;
  right: 10px;
}

/* *****************        ********************** */

@font-face {
  font-family: 'butler-bold';
  src: url('../fonts/Butler_Bold.otf');
}

@font-face {
  font-family: 'Kumbh Sans';
  src: url('../fonts/KumbhSans-Regular.woff2') format('woff2'),
    url('../fonts/KumbhSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kumbh Sans';
  src: url('../fonts/KumbhSans-Light.woff2') format('woff2'),
    url('../fonts/KumbhSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kumbh Sans';
  src: url('../fonts/KumbhSans-Bold.woff2') format('woff2'),
    url('../fonts/KumbhSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  background-color: var(--bn-dark-1);
  color: var(--bn-font-1);
  font-family: 'Kumbh Sans', sans-serif;
  /* height: 100vh; */
  /* width: 1080px; */
}

p {
  margin: 0 !important;
}

/* ul,
ol {
  margin-bottom: 0 !important;
} */

:focus {
  outline: none;
}

.kumbh {
  font-family: 'Kumbh Sans', sans-serif;
}

.temp-serif {
  font-family: 'butler-bold';
  line-height: 1;
}

.fw-thin {
  font-weight: 300;
}

.fw-reg {
  font-weight: normal;
}

.qrPayMsg {
  text-transform: capitalize;
  text-align: center;
}

.fw-bold {
  font-weight: bold;
}

.justify-content-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.justify-content-evenly {
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}

.cursor-pointer {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bgb {
  background-color: var(--bn-brand-2);
}

.bg-light-black {
  background-color: var(--bn-dark-1);
}

.bg-light-black-2 {
  background-color: var(--bn-dark-2);
}

.bg-greyish {
  background-color: var(--bn-border-color-1);
  /*rgba of #979797*/
}

.bg-pb {
  background-color: var(--bn-brand-color);
}

.blurred-white {
  color: var(--bn-font-2);
}

.z-5 {
  z-index: 5;
}

.sticky-bar-margin {
  margin-bottom: 188px;
}

.hero {
  width: 100%;
  /* background-color: var(--bn-brand-2); */
  position: relative;
}

.imgOverlay {
  /* opacity: 0.5; */
  position: relative;
  width: 100%;
}

.imgOverlay img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.hero-text {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(25%, rgba(0, 0, 0, 0.4)),
    color-stop(75%, rgba(0, 0, 0, 0.6))
  );
  background-image: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0.4) 25%,
    rgba(0, 0, 0, 0.6) 75%
  );
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.4) 25%,
    rgba(0, 0, 0, 0.6) 75%
  );
}

.header-links,
.footer-links a {
  color: var(--bn-font-1);
  font-family: 'Kumbh Sans', sans-serif;
  font-weight: normal;
  text-decoration: none;
  letter-spacing: 2.33px;
}

.footer-links a {
  font-size: 16px;
}

.footer-links li {
  padding: 1rem 0;
}

.blue-btn {
  color: var(--bn-font-1);
  cursor: pointer;
  font-family: 'Kumbh Sans', sans-serif;
  font-weight: bold;
  background-color: var(--bn-brand-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  letter-spacing: 2px;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.6rem 0.75rem 0.37rem 0.75rem;
  border-radius: 5px;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.4s ease-in-out,
    border-color 0.2s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.4s ease-in-out,
    border-color 0.2s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.4s ease-in-out,
    border-color 0.2s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.4s ease-in-out,
    border-color 0.2s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.4s ease-in-out,
    border-color 0.2s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  text-decoration: none;
  overflow: hidden;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  transition-timing-function: ease-in;
  outline: none !important;
}

.blue-btn {
  /* transition: all .2s ease-in-out !important; */
}

.blue-btn .spinner {
  color: inherit;
}

.blue-btn:hover {
  color: var(--bn-white);
  background-color: var(--bn-brand-color-dark);
  text-decoration: none;
  /* animation: scatter 1s; */
}

.blue-btn:hover .spinner {
  color: inherit;
}

.blue-btn:active {
  transform: scale(0.9);
  transition: 0.2s;
  color: var(--bn-white);
  background-color: var(--bn-brand-color-dark);
  /* border-color: var(--bn-white); */
}

.blue-btn.inactive-btn {
  color: var(--bn-font-1);
  background-color: var(--bn-light);
  cursor: not-allowed;
}

.blue-btn.inactive-btn:hover {
  color: var(--bn-font-1);
}

.btn-v2 {
  border: 1px solid var(--bn-brand-color);
  color: var(--bn-brand-color);
  background-color: transparent;
}

.btn-v3 {
  color: var(--bn-brand-color);
  background-color: var(--bn-white);
}

.btn-v3:hover,
.btn-v3:active {
  background-color: var(--bn-brand-color);
  color: var(--bn-font-1);
}

.btn-v4 {
  color: var(--bn-font-1);
  background-color: transparent;
  border: 2px solid white;
}

.btn-v4:hover,
.btn-v4:active {
  background-color: transparent;
  color: var(--bn-brand-color);
  border-color: var(--bn-brand-color);
}

.btn-v5 {
  color: var(--bn-font-1);
  background-color: var(--bn-dark-2);
}

.btn-v5:hover,
.btn-v5:active {
  background-color: var(--bn-white);
  color: var(--bn-dark-2);
}

.btn-v5.inactive {
  color: var(--bn-font-1);
  background-color: var(--bn-dark-2);
  cursor: not-allowed;
}

.modal-btn {
  color: var(--bn-font-1);
  border: none !important;
  background-color: var(--bn-dark-1);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50rem;
  font-family: 'Kumbh Sans', sans-serif;
  font-weight: normal;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.4s ease-in-out,
    border-color 0.4s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.4s ease-in-out,
    border-color 0.4s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.4s ease-in-out,
    border-color 0.4s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.4s ease-in-out,
    border-color 0.4s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.4s ease-in-out,
    border-color 0.4s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.modal-btn:hover,
.modal-btn:active,
.modal-btn.active {
  background-color: var(--bn-brand-color);
  color: var(--bn-font-1);
}

.header-links:hover,
.header-links:active,
.footer-links a:hover,
.footer-links a:active {
  color: var(--bn-font-1);
  text-decoration: none;
}

.dropdownClassic {
  padding: 0.6rem 2.5rem 0.37rem 1.5rem;
  border-radius: 8px;
  border: none;
  width: 212px !important;
  font-weight: bold;
  font-size: 20px;
  height: 64px !important;
  background-color: var(--bn-dark-2) !important;
  /* cursor: pointer; */
  color: var(--bn-font-1) !important;
  border-color: transparent !important;
  box-shadow: none;
  width: 100%;
  outline: 0;
  letter-spacing: 3.33px;
}

.date-picker-section .dropdownClassic {
  background-color: transparent !important;
}

.code .dropdownClassic {
  height: 100% !important;
  color: var(--bn-dark-1) !important;
  background-color: var(--bn-white) !important;
  border-radius: 10px !important;
  /* width: 160px !important; */
  width: 100% !important;
}

.dropdownClassic.inactive {
  color: var(--bn-font-2) !important;
  cursor: no-drop !important;
  /* pointer-events: none !important; */
}

.btn:focus,
.btn-primary:focus,
.btn-primary:hover,
.show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.dropdownItems {
  background-color: var(--bn-dark-2) !important;
  color: var(--bn-font-1) !important;
  width: 100%;
  overflow-y: auto;
  max-height: 200px;
  letter-spacing: 3.33px;
}

/* .code .dropdownItems{
	background-color: var(--bn-dark-2) !important;
	color: var(--bn-font-1) !important;
} */

.dropdown-menu {
  /* padding-top: 0 !important;
  padding-bottom: 0 !important; */
}

.dropdownItems > .dropdown-item {
  color: var(--bn-font-1);
  font-size: 20px;
  /* font-weight: bold; */
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: var(--bn-dark-1);
  text-decoration: none;
  background-color: var(--bn-white);
}

.dropdown-item:not(:last-child) {
  border-bottom: 1px solid var(--bn-white);
}

.dropdown > .btn {
  /* font-size: unset !important; */
  text-align: left;
  height: 100%;
}

.dropdownItems > .dropdown-divider {
  border-top: 1px solid var(--bn-border-color-1);
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--bn-brand-2) !important;
  background-color: var(--bn-white) !important;
}

.dropdown-toggle:after {
  content: url('../imgs/svgs/arrow-down.svg');
  position: absolute;
  top: 2px;
  right: 1rem;
  height: 100%;
  display: flex !important;
  align-items: center;
  border: none !important;
}

.code .dropdown-toggle:after {
  content: '';
  position: absolute;
  top: 45%;
  right: 1rem;
  height: 100%;
  display: flex !important;
  align-items: center;
  border-left: 10px solid transparent !important;
  border-right: 10px solid transparent !important;
  border-top: 10px solid var(--bn-dark-1) !important;
}

.code .kiosk-input {
  height: 35px;
  margin: 0;
  margin-bottom: 8px;
  width: calc(100% - 10px);
  font-size: 16px;
  padding: 2px 8px;
}

.code .dropdown-menu {
  min-width: 14rem;
  max-height: 400px;
}

.code .hg-theme-default .hg-button span {
  word-break: break-all;
}

.code .hg-theme-default.hg-layout-numeric .hg-button {
  width: 33.3%;
  height: 35px;
  font-size: 12px;
  align-items: center;
  display: flex;
  justify-content: center;
}

/* .code .dropdownItems {
  color: var(--bn-dark-1) !important;
  background-color: var(--bn-white) !important;
} */

.dropdowns {
  background: transparent;
  border: none;
  color: var(--bn-font-1);
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  vertical-align: middle;
  border-radius: 0.25rem;
  outline: 0;
  max-height: 200px;
}

.p-dd {
  padding: 0.375rem 0.75rem;
}

.dropdown:hover,
.dropdown:active,
.dropdown:focus {
  outline: 0;
  text-decoration: none;
}

.single-lang {
  padding: 0.6rem 1.5rem 0.37rem 1.5rem;
  border-radius: 8px;
  border: none;
  width: 150px !important;
  font-weight: bold;
  font-size: 20px;
  height: 44px !important;
  background-color: var(--bn-dark-2) !important;
  /* cursor: pointer; */
  color: var(--bn-font-1) !important;
  border-color: transparent !important;
  box-shadow: none;
  width: 100%;
  outline: 0;
  letter-spacing: 3.33px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.group {
  font-family: 'Kumbh Sans', sans-serif;
  background-color: var(--bn-dark-2);
  border-radius: 50rem;
  letter-spacing: 1.5px;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 1.25rem;
  overflow: hidden;
  /* height: 49px; */
  height: 40px;
  /* width: 160px; */
  font-size: 15.4px;
  font-weight: bold;
}

.book-ticket {
  background-color: var(--bn-dark-1);
  border-radius: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  z-index: 1;
}

.f-20 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.f-20 button {
  padding-right: 2rem;
}

.poster {
  border-radius: 12px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.hr {
  border-top: 1px solid var(--bn-white);
}

.hr-2 {
  border-top: 1px solid var(--bn-border-color-1);
}

.opacity-04 {
  opacity: 0.4;
}

.opacity-40 {
  opacity: 0.4;
}

/* .hero-2{
  position: relative;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.4) 85%,
   rgba(0, 0, 0, 0.5) -6%), url('./assets/avengers-endgame-small.png');
  width: 100%;
  height: auto;
}
.hero-text-2{
  color: var(--bn-font-1);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 5px;
} */

.bb-transition {
  -webkit-transition: border-bottom 0.25s ease-out;
  -o-transition: border-bottom 0.25s ease-out;
  transition: border-bottom 0.25s ease-out;
}

.feature-links a {
  color: var(--bn-font-1);
  font-weight: normal;
  text-decoration: none;
  opacity: 0.4;
  padding-bottom: 11px;
  -webkit-transition: border-bottom 0.25s ease-out;
  -o-transition: border-bottom 0.25s ease-out;
  transition: border-bottom 0.25s ease-out;
  border-bottom: 5px solid transparent;
  font-size: 16px;
  letter-spacing: 2px;
  white-space: nowrap;
  padding-right: 15px;
}

.feature-links a:hover,
.feature-links a:active,
.feature-links .active,
.feature-links .active a,
.userAuth .active {
  opacity: 1;
  /* color: var(--bn-font-1); */
  color: #fff !important;
  text-decoration: none;
  border-bottom: 5px solid var(--bn-brand-color-dark);
  font-weight: bold;
}

.dropdown-bg {
  border-radius: 19.5px;
  /* opacity: 60%; */
  /* background-color: var(--bn-dark-2); */
  background-color: var(--bn-dark-1);
  /* converted hash to rgb */
}

.m-bal {
  margin: 0 -15px;
}

.ls-06 {
  letter-spacing: 0.6px;
}

.ls-1 {
  letter-spacing: 1px;
}

.ls-15 {
  letter-spacing: 1.5px;
}

.ls-2 {
  letter-spacing: 2px;
}

.ls-3 {
  letter-spacing: 3px;
}

/* .vab{
  vertical-align: bottom;
} */

/* .hover-p {
  cursor: pointer;
} */

.card-holder,
.holder {
  position: relative;
}

.card-holder .movie-card:hover::after,
.holder:hover::after {
  content: '';
  position: absolute;
  height: 100%;
  top: 0;
  width: 100%;
  left: 0;
  background-color: var(--bn-dark-1);
}

.movie-card,
.holder {
  overflow: hidden;
}

.movie-card img,
.img-card {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.6s ease-in;
  transition: -webkit-transform 0.6s ease-in;
  -o-transition: transform 0.6s ease-in;
  transition: transform 0.6s ease-in;
  transition: transform 0.6s ease-in, -webkit-transform 0.6s ease-in;
}

.movie-card:hover img,
.holder:hover .img-card {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.hover-c,
.hover-c1,
.hover-c2 {
  /* visibility: hidden; */
  opacity: 0;
  -webkit-transition: opacity 0.75s;
  -o-transition: opacity 0.75s;
  transition: opacity 0.75s;
}

.cursor-pointer:hover .hover-c,
.cursor-pointer:hover .hover-c1,
.cursor-pointer:hover .hover-c2 {
  /* visibility: visible; */
  opacity: 1;
}

.hover-p2 {
  cursor: pointer;
}

.hover-c1 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(16%, rgba(0, 0, 0, 0.4)),
    color-stop(93%, rgba(0, 0, 0, 0.4))
  );
  background-image: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0.4) 16%,
    rgba(0, 0, 0, 0.4) 93%
  );
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.4) 16%,
    rgba(0, 0, 0, 0.4) 93%
  );
}

/* .hover-c1, .hover-c2 {
  visibility: hidden;
}

.hover-p2:hover .hover-c2 {
  visibility: visible;
} */

.hover-p2:hover .hover-c1,
.hover-p2:hover .hover-c2 {
  opacity: 1;
}

/* .bg-text-left::before,
.bg-text-right::after {
  position: absolute;
  opacity: 0.1;
  font-family: 'Arial';
  font-weight: bold;
  overflow-x: hidden;
}

.bg-text-left::before {
  content: '01';
  left: 0;
}

.bg-text-right::after {
  content: '02';
  right: 0;
} */

.text-limit {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  line-height: 1.4;
}

.text-limit.v2 {
  max-height: 110px;
  line-height: 1.1;
}

.text-limit.v3 {
  /* max-height: 60px; */
  -webkit-line-clamp: 2;
}

.text-limit.v4 {
  /* max-height: 35px; */
  -webkit-line-clamp: 1;
}

.text-limit.v5 {
  /* max-height: 70px; */
  -webkit-line-clamp: 3;
}

.brds-8 {
  border-radius: 8px;
}

.brds-10 {
  border-radius: 10px;
}

.bt-1 {
  border-top: 1px solid var(--bn-border-color-1);
  /* Color #979797 */
}

.br-1 {
  border-right: 1px solid var(--bn-border-color-1);
  /* Color #979797 */
}

.bb-1 {
  border-bottom: 1px solid var(--bn-border-color-1);
  /* Color #979797 */
}

.bl-1 {
  border-left: 1px solid var(--bn-border-color-1);
  /* Color #979797 */
}

.b-white {
  border: 1px solid var(--bn-white);
}

.modal-content {
  background: none !important;
  border: none !important;
  z-index: 10005 !important;
}

.w-auto {
  width: auto;
}

.img-fit img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.img-fit-2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.arrow-rotate {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
}

.arrow-rotate:active,
.arrow-rotate.active {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* ---------Cutsom Scrollbar--------- */

/* width */

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  background-color: var(--bn-scrollbar);
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: var(--bn-dark-2);
  border-radius: 5px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: var(--bn-scrollbar-hover);
}

/* ----------------------------------------- Header -----------------------------------------*/

.sidebar {
  position: fixed;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  color: var(--bn-font-1);
  background-color: var(--bn-dark-1);
  z-index: 1000;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
  overflow-y: auto;
}

.showbar {
  left: 0;
}

.cc-input {
  outline: 0;
  border: none;
  border-radius: 10px;
  color: var(--bn-font-1);
}

/* login/signup section */

.f-input {
  outline: 0;
  border: none;
  border-radius: 10px;
}

.f-input:active {
  outline: 0;
}

.login-signup-modal {
  z-index: 10010;
}

/* -------------------------------------Page---Checkout Container------------------------------- */

.mb-36 {
  margin-bottom: 36px;
}

/* .mb-184 {
  margin-bottom: 184px;
} */

.accordion-3 .accordion {
  border: none !important;
}

.accordion-3 .card {
  background-color: var(--bn-brand-2) !important;
  color: var(--bn-font-1) !important;
  padding: 0 0 32px 0 !important;
  overflow: initial !important;
}

.accordion-3 .card-header {
  color: var(--bn-font-1) !important;
  background-color: var(--bn-dark-1) !important;
  border: none !important;
  border-bottom: none !important;
  padding: 0 1.25rem !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  cursor: pointer;
}

.accordion-3 .card-body {
  background-color: var(--bn-dark-1) !important;
  padding: 0 1.25rem 1.25rem 1.25rem;
}

.accordion-arrow {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.clicked .accordion-arrow {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.lh-1 {
  line-height: 1;
}

/* -------------------------------------Page---Seat Layout------------------------------- */

.stw {
  min-height: 75px;
  /* border-radius: 14.4px; */
  /* background-image: linear-gradient(to top, #20356b 117%, #6e86c3 -211%); */
  display: flex;
  justify-content: center;
  /* margin-bottom: 48px; */
}

.stw-text {
  position: absolute;
  left: 0;
  color: var(--bn-font-1);
  font-family: 'Kumbh Sans', sans-serif;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 5.12px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* .stw-text::before,
.stw-text::after {
  content: url('../imgs/svgs/arrow-up.svg');
  height: 18px;
  width: 18px;
} */

/* .stw-text::before{
  margin-right: 1rem;
} */

/* .stw-text::after{
  margin-left: 1rem;
} */

.pno-section {
  max-height: 60vh;
  overflow-y: auto;
}

.pno-section::-webkit-scrollbar-track {
  background-color: transparent;
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 10px;
}

.code-input {
  color: var(--bn-font-1);
  background-color: transparent;
  outline: 0;
  border: none;
}

.b-3 {
  max-height: 90px;
  border-radius: 10px;
}

.dropdown-code .dropdownClassic {
  color: var(--bn-font-2) !important;
}

/* bx class to be replaced by bl-1 and br-1 */

.bx {
  border-left: 1px solid var(--bn-border-color-1);
  border-right: 1px solid var(--bn-border-color-1);
}

/* .table {
  margin-bottom: 0 !important;
} */

.table .table-borderless {
  margin-bottom: 40px !important;
}

.table td {
  /* padding: 6px !important; */
}

.table td,
.table th {
  vertical-align: middle !important;
  padding: 0;
}

.shd {
  width: 100%;
  margin: 1.5rem 0;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shd span {
  padding: 0 1rem;
  text-align: center;
  background-color: var(--bn-dark-1);
  color: var(--bn-font-1);
  border-radius: 50rem;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shd::before,
.shd::after {
  background-color: var(--bn-border-color-1);
  height: 0.8px;
  width: 100%;
  content: '';
}

div.category-title {
  position: relative;
  overflow: hidden;
  text-align: center;
  margin: 1.5rem 0;
}

div.category-title span {
  display: inline-block;
  vertical-align: baseline;
  zoom: 1;
  /* display: inline; */
  vertical-align: middle;
  position: relative;
  padding: 5px 2rem;
  background-color: var(--bn-dark-1);
  color: var(--bn-font-1);
  border-radius: 50rem;
  font-weight: bold;
}

div.category-title span:before,
div.category-title span:after {
  content: '';
  display: block;
  width: 1000px;
  position: absolute;
  top: 50%;
  border-top: 1px solid var(--bn-border-color-1);
}

div.category-title span:before {
  right: 100%;
}

div.category-title span:after {
  left: 100%;
}

.first-table.table {
  margin: 0 auto !important;
  width: auto !important;
}

.second-table .table {
  width: auto !important;
}

.cell {
  margin: 4px;
  /* border-radius: 11.5px; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.seats .cell {
  /* background-color: rgba(216, 216, 216, 0.2); */
  /* color: rgba(216, 216, 216, 0.8); */
  cursor: pointer;
  -webkit-transition: color 0.15s ease-in, background-color 0.3s ease-out;
  -o-transition: color 0.15s ease-in, background-color 0.3s ease-out;
  transition: color 0.15s ease-in, background-color 0.3s ease-out;
  /* background-image: url("../../assets/imgs/svgs/seat-sl.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; */
  color: var(--bn-font-1);
  mask: url('../../assets/imgs/svgs/seat-sl.svg');
  mask-position: center;
  mask-size: cover;
  mask-repeat: no-repeat;
  background-color: var(--bn-white);
  font-weight: bold;
}

.seats .wheelChairIcon {
  cursor: pointer;
  -webkit-transition: color 0.15s ease-in, background-color 0.3s ease-out;
  -o-transition: color 0.15s ease-in, background-color 0.3s ease-out;
  transition: color 0.15s ease-in, background-color 0.3s ease-out;
  color: var(--bn-font-1);
  mask: url('../../assets/imgs/svgs/wheel-chair.svg');
  mask-position: center;
  mask-size: cover;
  mask-repeat: no-repeat;
  background-color: var(--bn-white);
  font-weight: bold;
  width: 63px;
}
.seats .wheelChairIcon:active,
.seats .wheelChairIcon.active {
  mask: url('../../assets/imgs/svgs/wheel-chair-selected.svg');
  mask-position: center;
  mask-size: cover;
  mask-repeat: no-repeat;
  background-color: var(--bn-brand-color);
  width: 50px;
}
.wheelchair_seat_name {
  color: #fff;
  font-weight: bold;
}
/* .seats .cell:hover {
  background-image: url("../../assets/imgs/svgs/seat-sl-selected.svg");
} */

.seats .cell:active,
.seats .cell.active {
  /* background-color: #20356b;
  color: var(--bn-font-1); */
  /* background-image: url("../../assets/imgs/svgs/seat-sl-selected.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; */
  mask: url('../../assets/imgs/svgs/seat-sl-selected.svg');
  mask-position: center;
  mask-size: cover;
  mask-repeat: no-repeat;
  background-color: var(--bn-brand-color);
}

/* .seats .cell.inactive {
  cursor: not-allowed;
  background-color: rgba(250, 250, 250, 0.1);
  color: rgba(216, 216, 216, 0.8);
} */

.seats .cell.reserved {
  cursor: not-allowed;
  /* background-image: url("../../assets/imgs/svgs/seat-sl-occupied.svg"); */
  mask: url('../../assets/imgs/svgs/seat-sl-occupied.svg');
  mask-position: center;
  mask-size: cover;
  mask-repeat: no-repeat;
  background-color: var(--bn-white);
}

.seats .cell.covid-blocked {
  cursor: not-allowed;
  /* background-image: url("../../assets/imgs/svgs/seat-sl-occupied.svg"); */
  mask: url('../../assets/imgs/svgs/shield-fill-minus.svg');
  color: var(--bn-white);
  mask-position: center;
  mask-size: cover;
  mask-repeat: no-repeat;
  /* background-color: var(--bn-white); */
  width: 45px;
  height: 45px;
  margin: 0 auto;
}

.seats .cell.empty {
  background-color: transparent;
  color: transparent;
  border: transparent;
  cursor: unset;
  /* background-image: none; */
  mask: none;
}

.sofa {
  cursor: pointer;
  /* background-image: url("../../assets/imgs/svgs/sofa.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; */
  mask: url('../../assets/imgs/svgs/sofa.svg');
  mask-position: center;
  mask-size: cover;
  mask-repeat: no-repeat;
  background-color: var(--bn-white);
  margin: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--bn-font-1);
  font-weight: bold;
}

.sofa > span:nth-child(2) {
  display: inline-block;
  margin-left: 15px;
}

.sofa:hover,
.sofa:active,
.sofa.active {
  /* background-image: url("../../assets/imgs/svgs/sofa-selected.svg"); */
  mask: url('../../assets/imgs/svgs/sofa-selected.svg');
  mask-position: center;
  mask-size: cover;
  mask-repeat: no-repeat;
  background-color: var(--bn-brand-color);
}

.sofa.reserved {
  cursor: not-allowed;
  /* background-image: url("../../assets/imgs/svgs/sofa-selected.svg"); */
  mask: url('../../assets/imgs/svgs/sofa-selected.svg');
  mask-position: center;
  mask-size: cover;
  mask-repeat: no-repeat;
  background-color: var(--bn-brand-color);
}

sofa.empty {
  background-color: transparent;
  color: transparent;
  border: transparent;
  cursor: unset;
  /* background-image: none; */
  mask: none;
}

.wheel-chair {
  cursor: pointer;
  /* background-image: url("../../assets/imgs/svgs/wheel-chair.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; */
  mask: url('../../assets/imgs/svgs/wheel-chair.svg');
  mask-position: center;
  mask-size: cover;
  mask-repeat: no-repeat;
  background-color: var(--bn-white);
  margin: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--bn-font-1);
  font-weight: bold;
}

.wheel-chair:hover,
.wheel-chair:active,
.wheel-chair.active {
  /* background-image: url("../../assets/imgs/svgs/wheel-chair-selected.svg"); */
  mask: url('../../assets/imgs/svgs/wheel-chair-selected.svg');
  mask-position: center;
  mask-size: cover;
  mask-repeat: no-repeat;
  background-color: var(--bn-brand-color);
}

.wheel-chair.reserved {
  cursor: not-allowed;
  /* background-image: url("../../assets/imgs/svgs/wheel-chair-selected.svg"); */
  mask: url('../../assets/imgs/svgs/wheel-chair-selected.svg');
  mask-position: center;
  mask-size: cover;
  mask-repeat: no-repeat;
  background-color: var(--bn-brand-color);
}

.wheel-chair.empty {
  background-color: transparent;
  color: transparent;
  border: transparent;
  cursor: unset;
  /* background-image: none; */
  mask: none;
}

/* .wheel-chair:hover, .wheel-chair:active, .wheel-chair.active{
  background-image: url("../imgs/svgs/wheel-chair.svg");
} */

.cell,
.wheel-chair .sofa {
  height: 47px;
  font-size: 1rem;
}
.wheelChairIcon {
  height: 50px;
  font-size: 1rem;
}
.cell {
  width: 58px;
}

.sofa {
  width: 77px;
}

.wheel-chair {
  width: 47px;
}

/* .passage {
  width: 80px;
} */

.premium {
  font-size: 25.9px;
  letter-spacing: 8.64px;
}

.premium::before,
.premium::after {
  content: '★★';
}

.sl-fnb-ic {
  height: 175px;
  width: 135px;
}

.sticky-movie-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.sticky-alert-top {
  position: sticky !important;
  top: 10px;
  z-index: 1000;
}

/* ----------------------------------------Page-Movie Container------------------------------ */

.choose-date {
  -webkit-transition: background-color 0.4s;
  -o-transition: background-color 0.4s;
  transition: background-color 0.4s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--bn-dark-1);
  border-radius: 6px;
  font-family: 'Kumbh Sans', sans-serif;
  cursor: pointer;
}

.showtime {
  color: var(--bn-font-1);
  background-color: transparent;
  border: 1px solid white;
}

.showtime {
  -webkit-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
  cursor: pointer;
}

.showtime:hover {
  color: var(--bn-font-1);
  background-color: var(--bn-brand-color);
  border: 1px solid var(--bn-brand-color);
}

.showtime .dimension-lg {
  position: relative;
  right: -12px;
  top: 0;
}

.choose-date:hover {
  color: var(--bn-font-1);
  background-color: var(--bn-brand-color);
}

.showtime:active,
.showtime.active,
.choose-date:active,
.choose-date.active,
.selected-date {
  color: var(--bn-font-1);
  background-color: var(--bn-brand-color);
  border: 1px solid var(--bn-brand-color);
}

.showtime.inactive,
.showtime.inactive:hover {
  opacity: 0.4;
  cursor: not-allowed;
  color: var(--bn-font-1);
  background-color: transparent;
  border: 1px solid white;
}

.modal-2 .modal-content {
  border-radius: 8px !important;
}

.modal-2 .modal-header,
.modal-2 .modal-body,
.modal-2 .modal-footer {
  color: var(--bn-font-1);
  background-color: var(--bn-dark-2);
  padding-right: 0;
}

.modal-2 .modal-header {
  border-bottom: 1px solid var(--bn-border-color-1) !important;
  padding: 0.5rem 1rem;
  align-items: baseline !important;
}

.modal-2 .modal-footer {
  padding: 0.75rem 0;
}

.modal-2 .modal-footer > * {
  margin: 0;
}

.modal-2 .close {
  opacity: 1 !important;
  text-shadow: none !important;
  color: var(--bn-font-1) !important;
  font-family: 'Kumbh Sans' !important;
  font-weight: normal !important;
  outline: 0;
  padding: 12px 12px !important;
}

.modal-2 .close:hover {
  color: var(--bn-font-1) !important;
}

/* ----------------------------------------Page-FnB------------------------------------------ */

.fnb-section {
  /* max-height: 60vh; */
  max-height: 55vh;
  overflow-y: auto;
  zoom: 0.7;
}

.fnb-section::-webkit-scrollbar-track {
  background-color: transparent;
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 10px;
}

/* .list-inline-item:active {
  background-color: green
} */

.modifier-btn {
  height: 25pt;
  min-width: 80pt;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--bn-font-1);
  background-color: var(--bn-brand-color);
  border-radius: 50rem;
  padding: 0.75rem;
  font-weight: bold;
}

.modifier-btn.inactive {
  background-color: var(--bn-dark-2);
}

.modifier-btn > .mod-add,
.modifier-btn > .mod-remove {
  cursor: pointer;
  transition: all 0.2s;
  padding: 8px;
}

.modifier-btn > .mod-add:active,
.modifier-btn > .mod-remove:active {
  transform: scale(0.8);
}

.modifier-btn > .mod-add.inactive,
.modifier-btn > .mod-remove.inactive {
  color: var(--bn-font-2);
}

.fnb-menu::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.fnb-menu {
  height: 112px;
  overflow-x: auto;
  padding: 0 1rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fnb-menu li {
  margin: 0 1rem;
  letter-spacing: 6px;
  font-weight: normal;
  color: var(--bn-font-2);
  cursor: pointer;
  font-size: 24px;
  border-bottom: 5px solid transparent;
}

.fnb-menu li:hover,
.fnb-menu li:active,
.fnb-menu li.active {
  color: var(--bn-font-1);
  border-bottom: 5px solid var(--bn-brand-color);
}

.session-timer {
  border-radius: 50rem;
  font-size: 30px;
  background-color: var(--bn-dark-2);
  font-weight: bold;
  padding: 0 2rem;
  min-width: 160;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.session-timer > span {
  padding-top: 4px;
}

.session-timer img {
  height: 24px !important;
  padding-left: 8px;
}

.fnbItem {
  border-radius: 10px;
  display: flex;
  flex-flow: column nowrap;
  background-color: var(--bn-brand-2);
}

.fnbItem img {
  /* height: 124px; */
   height: 100%;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  object-position: top;
}

.fnbItem .itemName {
  font-size: 14px;
  color: var(--bn-font-1);
  font-weight: normal;
}

.fnbItem .itemCal {
  font-size: 14px;
  color: var(--bn-font-2);
  font-weight: normal;
  text-align: right;
}

.fnbItem .itemPrice {
  font-size: 11px;
  color: var(--bn-font-1);
  font-weight: bold;
}

.plus-icon,
.minus-icon {
  background-color: var(--bn-brand-color);
  color: var(--bn-font-1);
  height: 25px;
  width: 25px;
  border-radius: 50%;
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
  cursor: pointer;
  font-size: 40px;
}

.plus-icon:hover,
.minus-icon:hover {
  background-color: var(--bn-white);
  color: var(--bn-brand-color);
}

.plus-icon:active,
.minus-icon:active {
  transform: scale(0.8);
}

.items-div {
  overflow: auto;
  max-height: 35vh;
}

/* ----------------------------------------Timeout Container------------------------------------ */

.modal-time-up .modal-header,
.modal-time-up .modal-body,
.modal-time-up .modal-footer {
  color: var(--bn-font-1);
  background-color: var(--bn-dark-2);
}

.modal-time-up .modal-header {
  border-bottom: 1px solid var(--bn-border-color-1) !important;
  padding: 0.5rem 1rem !important;
}

.modal-time-up .modal-footer {
  border-top: 1px solid var(--bn-border-color-1) !important;
  padding: 0.25rem 0.75rem !important;
}

.modal-time-up .close {
  opacity: 1 !important;
  text-shadow: none !important;
  color: var(--bn-font-1) !important;
  font-size: 48px !important;
  font-family: 'Kumbh Sans' !important;
  font-weight: normal !important;
  outline: 0;
}

.modal-time-up .close:hover {
  color: var(--bn-font-2) !important;
}

/* ----------------------------------------Profile-Details------------------------------------ */

.profile-menu p {
  padding: 8px 0;
  margin: 16px 0;
  font-family: 'Kumbh Sans', sans-serif;
  font-weight: bold;
  cursor: pointer;
  border-right: 8px solid transparent;
  transition: color 0.4s, border-right 0.6s;
}

.profile-menu p:hover,
.profile-menu p:active,
.profile-menu p.active {
  color: var(--bn-brand-color);
  border-right: 8px solid var(--bn-brand-color);
}

/* -------------------------terms and conditions------------------------------ */

.accordion-1 .accordion {
  border: none !important;
}

.accordion-1 .card {
  background-color: var(--bn-brand-2) !important;
  color: var(--bn-font-1) !important;
  padding: 32px 0 !important;
  border-bottom: 1px solid var(--bn-border-color-1) !important;
}

.accordion-1 .card-header {
  background-color: var(--bn-dark-1) !important;
  border: none !important;
  border-bottom: none !important;
  padding: 0 1.25rem !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.accordion-1 .card-body {
  padding: 1.25rem 0;
}

/* -------------------------profile-details------------------------------ */

.accordion-2 .accordion {
  border: none !important;
}

.accordion-2 .card {
  background-color: var(--bn-brand-2) !important;
  color: var(--bn-font-1) !important;
  padding: 32px 0 !important;
}

.accordion-2 .card-header {
  background-color: var(--bn-dark-1) !important;
  border: none !important;
  border-bottom: none !important;
  padding: 0 1.25rem !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  border-radius: 10px !important;
}

.accordion-2 .card-body {
  padding: 1.25rem !important;
  background-color: var(--bn-dark-1) !important;
}

/* -----------------------------------------fnb-checkout------------------------------------------ */

.fnb-checkout .bg-img {
  height: 300px;
}

.fnb-checkout .moviePoster {
  height: 128px;
  width: 97px;
  object-fit: cover;
  object-position: top;
  border-radius: 0.5rem;
}

.fnb-checkout .blurred-text {
  color: var(--bn-font-2);
  font-weight: normal;
}

.fnb-checkout .blurred-text-2 {
  color: var(--bn-font-2);
  font-size: 14px;
}

.fnb-checkout .qr {
  height: 128px;
  width: 128px;
  object-fit: contain;
  object-position: center;
}

@keyframes invalid {
  0% {
    left: 0;
  }

  20% {
    left: -1.5rem;
  }

  40% {
    left: 1.5rem;
  }

  60% {
    left: -1.5rem;
  }

  80% {
    left: 1.5rem;
  }

  100% {
    left: 0;
  }
}

.kiosk-input {
  height: 80px;
  width: 480px;
  color: var(--bn-dark-1);
  background-color: var(--bn-white);
  border-radius: 10px;
  padding: 10px 40px;
  font-size: 24px;
  font-weight: normal;
  margin: 100px auto 0 auto;
  outline: 0;
  border: none;
}

.kiosk-input.error {
  /* position: relative; */
  color: var(--bn-font-1);
  background-color: var(--bn-error);
  /* animation: invalid 1s; */
}

.kiosk-input.error::placeholder {
  color: var(--bn-font-2);
}

/* ------------------ */

.login_form .msg {
  padding-top: 420px;
  padding-bottom: 100px;
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  /* border-bottom: 1px solid var(--bn-border-color-1); */
}

.login_form .blue-btn {
  width: 340px;
  height: 80px;
  font-size: 24px;
  margin-top: 146px;
}

.login_form .error-p {
  padding-top: 0.5rem;
}

.scroll-arrows {
  user-select: none;
  z-index: 100;
  position: fixed;
}

.scroll-arrows figure {
  position: fixed;
  right: 50px;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: var(--bn-brand-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  padding: 24px;
  transition: background-color 0.5s, transform 0.25s;
}

.scroll-arrows .scroll-arrows__up {
  bottom: 350px;
}

.scroll-arrows .scroll-arrows__down {
  bottom: 250px;
}

.scroll-arrows figure img {
  width: 100%;
}

.scroll-arrows figure:active {
  transform: scale(0.9) !important;
}

.scroll-arrows figure:active,
.scroll-arrows figure:hover {
  background-color: var(--bn-brand-color-dark);
}

.scroll-arrows figure.inactive {
  background-color: var(--bn-dark-2);
  pointer-events: none;
  cursor: default;
}

/* ----------------------------------------Responsiveness------------------------------------ */

/* Responsive Fonts */

@media (min-width: 0px) {
  /* For Smartphones */
  .f-input {
    font-size: 14px;
  }

  /* .feature-links a {
    font-size: 16px;
    letter-spacing: 4px;
  } */

  .frs-6 {
    font-size: 6px;
  }

  .frs-8 {
    font-size: 8px;
  }

  .frs-10 {
    font-size: 10px;
  }

  .frs-12 {
    font-size: 12px;
  }

  .frs-14 {
    font-size: 14px;
  }

  .frs-16 {
    font-size: 15px;
  }

  .frs-18 {
    font-size: 18px;
  }

  .frs-20 {
    font-size: 20px;
  }

  .frs-22 {
    font-size: 22px;
  }

  .frs-24 {
    font-size: 24px;
  }

  .frs-26 {
    font-size: 26px;
  }

  .frs-28 {
    font-size: 28px;
  }

  .frs-32 {
    font-size: 32px;
  }

  .frs-36 {
    font-size: 36px;
  }

  .frs-50 {
    font-size: 50px;
  }
}

/* Responsive Height and Width */

@media (min-width: 0px) {
  /* For Smartphones */
  .slider-h-296 {
    height: 296px;
  }

  .slider-h-231 {
    height: 231px;
  }

  .ls-4 {
    letter-spacing: 2px;
  }

  .ls-6 {
    letter-spacing: 2px;
  }

  .modal-3 .modal-header,
  .dls {
    letter-spacing: 2px;
  }

  .modal-3 .close,
  .modal-2 .close {
    font-size: 36px !important;
  }

  .back {
    position: absolute;
    left: 1rem;
    top: 24px;
    display: block;
    font-size: 0;
  }

  .fixed-remover,
  .fi:last-child {
    margin-bottom: 190px;
    /* padding-bottom: 40px; */
  }

  .choose-date {
    height: 60px;
    width: 60px;
  }

  .icon-18 {
    height: 18px;
    width: 18px;
    cursor: pointer;
  }

  .img-42-66 {
    height: 52px;
    max-width: 32px;
  }

  .img-64 {
    height: 52px;
    max-width: 52px;
  }

  .img-88 {
    height: 70px;
    max-width: 70px;
  }

  .img-115 {
    height: 85px;
    max-width: 85px;
    object-fit: cover;
    object-position: top;
  }

  .img-150 {
    height: 90px;
    max-width: 90px;
  }

  .modal-1 .modal-dialog,
  .modal-2 .modal-dialog,
  .modal-3 .modal-dialog {
    max-width: 900px !important;
    margin: 0.5rem auto;
  }

  .modal-time-up .modal-dialog {
    max-width: 600px !important;
    margin: 0.5rem auto;
  }

  .modal-4 .modal-dialog {
    margin: 0.5rem auto;
  }

  .modal-5 .modal-dialog {
    max-width: 600px !important;
  }

  .modal-btn {
    /* margin: 8px; */
    letter-spacing: 1px;
    min-width: 110px;
    height: 42px;
  }

  .shd {
    height: 36px;
    font-size: 12px;
    letter-spacing: 2px;
  }

  div.category-title span {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 6.67px;
  }

  .mxh-14 {
    height: 14px;
  }

  .mxh-24 {
    height: 24px;
  }

  .mxh-29 {
    height: 29px;
  }

  .mxh-31 {
    height: 31px;
  }

  .mxh-36 {
    height: 36px;
  }

  .mxh-38 {
    height: 38px;
  }

  .mxh-47 {
    height: 47px;
  }

  .mxh-50 {
    height: 50px;
  }

  .mxh-55 {
    height: 55px;
  }

  .mxh-58 {
    height: 58px;
  }

  .mxh-60 {
    height: 60px;
  }

  .mxh-63 {
    height: 63px;
  }

  .mxh-70 {
    max-height: 70px;
  }

  .mxh-107 {
    height: 107px;
  }

  .mxh-128 {
    height: 128px;
  }

  .mxh-131 {
    height: 131px;
  }

  .mxh-140 {
    height: 140px;
  }

  .mxh-161 {
    height: 161px;
  }

  .mxh-300,
  .mxh-300 img {
    height: 300px;
  }

  .mxw-300 img {
    width: 300px;
  }

  .mxh-340 {
    max-height: 340px;
    width: 100%;
  }

  .mxh-360 {
    height: 360px;
  }

  .slider-h-622 {
    height: 300px;
  }

  .mxw-58 {
    width: 45px;
  }

  .mxw-70 {
    width: 58px;
  }

  .mxw-90 {
    width: 70px;
  }

  .mxw-170 {
    width: 95px;
  }

  .mxw-180 {
    width: 160px;
  }

  .mxw-200 {
    width: 115px;
  }

  .mxw-252 {
    width: 200px;
  }

  .mxw-290 {
    width: 290px;
  }

  /* .mxw-300{
    width: 260px;
  } */
  .mxw-400 {
    width: 400px;
  }

  .mb-5s {
    margin-bottom: 3rem;
  }

  .mb-20 {
    margin-bottom: 12px;
  }

  .mt-31 {
    margin-top: 20px !important;
  }

  .mb-31 {
    margin-bottom: 20px !important;
  }

  .mb-40 {
    margin-bottom: 16px;
  }

  .pl-5s {
    padding-left: 3rem;
  }

  .stw-text {
    /* top: 5px; */
    bottom: 23px;
  }

  .px-30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .mmx-30 {
    margin-left: -30px;
    margin-right: -30px;
  }
}

/*------------------------------------Slick Slider---------------------------------*/

/* Overriding the classes */

.slick-prev:before,
.slick-next:before {
  font-size: 0 !important;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: var(--bn-brand-color) !important;
}

.slick-dots li button:before {
  font-size: 0 !important;
}

/* Forms */

.form-error-text {
  color: var(--bn-error);
}

/* General */

.pb-12 {
  padding-bottom: 12px;
}

.pt-12 {
  padding-top: 12px;
}

.pl-12 {
  padding-left: 12px;
}

.pr-12 {
  padding-right: 12px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.pr-20 {
  padding-right: 20px;
}

/* 404 Page */

.page-404 {
  margin: 6rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.page-404 > .heading {
  font-size: 5rem;
}

.page-404 > .message {
  font-size: 2rem;
}

.page-404 > a {
  font-size: 1.5rem;
  text-decoration: none;
}

/* Slick slider dates fix */

.slick-track-no-margin .slick-track {
  margin-left: 0 !important;
}

/* custom spinner */

.c-spiner-container {
  background-color: var(--bn-brand-2);
  opacity: 0.8;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1055;
}

/*MOVIE LISTS PAGE*/

.movie-box .movie-img {
  height: 300px;
  width: 100%;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  cursor: pointer;
}

.movie-box .movie-img:hover {
  -webkit-transform: scale(1.07);
  -ms-transform: scale(1.07);
  transform: scale(1.07);
  -webkit-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.movie-box .btn {
  padding: 07px;
}

.movie-box .movie-img img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.movie-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 15px;
  padding-bottom: 10px;
}

.movie-content .movie-time::before {
  content: '';
  border-left: 1px solid white;
  padding: 03px;
}

.movie-content .movie-pg::after {
  content: '';
  padding: 03px;
}

.movie-content .right-content {
  width: 40%;
}

.movie-content .movie-rate {
  background-color: var(--bn-dark-2);
  font-family: Kumbh Sans;
  border-radius: 15px;
  padding-top: 10px;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.movie-content .movie-lang {
  font-size: 12px;
  font-family: Kumbh Sans;
  padding-top: 10px;
  float: right;
}

.movie-content .movie-rate h6 {
  font-size: 10px;
  font-weight: bold;
}

.movie-content .left-content {
  width: 60%;
  height: 48px;
  max-height: 50px;
  overflow: hidden;
  height: 48px;
  max-height: 50px;
  cursor: default;
  margin-bottom: 5px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: no-wrap;
}

.tabs-movie a {
  font-family: 'Kumbh Sans', sans-serif;
  font-weight: bold;
  letter-spacing: 2.25px;
  padding-bottom: 8px;
  color: var(--bn-font-2);
  cursor: pointer;
  -webkit-transition: color 0.2s, border-color 0.3s;
  -o-transition: color 0.2s, border-color 0.3s;
  transition: color 0.2s, border-color 0.3s;
  border-bottom: 4px solid transparent;
}

.tabs-movie a[aria-selected='true'] {
  background: transparent !important;
  color: var(--bn-font-1);
  border: 1px solid transparent !important;
  border-bottom: 4px solid var(--bn-brand-color) !important;
}

.tabs-movie > a:focus,
.tabs-movie > a:hover {
  outline: none !important;
}

.tabs-movie .nav-link:focus,
.tabs-movie .nav-link:hover {
  border-color: transparent;
}

.custom-input .form-control {
  background-color: var(--bn-dark-2);
  border-radius: 10px;
  border: 1px solid var(--bn-dark-2);
  text-align: center;
  height: 50px;
}

.no-page > .inner-content {
  background-image: url('../../assets/imgs/svgs/error-404.svg');
  background-position: right;
  background-repeat: no-repeat;
  background-color: var(--bn-dark-1);
  /* background: var(--bn-dark-1); */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
  /* justify-content: space-around; */
  padding: 20px 0px;
  border-radius: 02px;
}

.no-page .btn {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 30px;
}

.no-page .heading {
  font-family: Butler-Bold;
  font-size: 100px;
  width: 25%;
  text-align: center;
}

.no-page .message {
  font-family: Kumbh Sans;
  /* padding-left: 72px; */
  /* font-size: 45px;*/
  font-weight: bold;
  width: 50%;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.voucher-input {
  background-color: var(--bn-light);
  /* opacity: 0.2; */
  color: var(--bn-font-1);
  border-radius: 03px;
}

.tnc-page .heading {
  font-family: Butler-Bold;
  font-size: 65px;
}

.tnc-accordion .card-header {
  font-family: 'Kumbh Sans';
  padding: 15px !important;
  font-weight: bold;
  letter-spacing: 2.4px;
  font-size: 18px;
  cursor: pointer;
}

.tnc-accordion .card-body {
  font-family: 'Kumbh Sans';
  font-size: 18px;
  letter-spacing: 1.2px;
  text-align: justify;
}

.tnc-accordion .card-header.hidden:after {
  width: 16px;
  height: 16px;
  position: relative;
  content: '';
  margin-left: auto;
  display: block;
  background: url('../../assets/imgs/svgs/arrow-down.svg') 0 0 no-repeat;
  background-size: 100%;
}

.tnc-accordion .card-header.show:after {
  width: 16px;
  height: 16px;
  position: relative;
  content: '';
  margin-left: auto;
  display: block;
  background: url('../../assets/imgs/svgs/arrow-up.svg') 0 0 no-repeat;
  background-size: 100%;
}

.exp-page .heading {
  font-family: 'Butler-Bold';
  /* font-size: 70px; */
  text-align: left;
  line-height: 1;
}

/* .exp-page .title {
  font-size: 16px;
  font-family: 'kumbh Sans';
  letter-spacing: 0.2px;
  text-align: justify;
} */

.exp-page .sub-heading {
  font-family: 'Butler-Bold';
}

/* .exp-page .desc {
  font-family: 'Kumbh Sans';
  font-size: 14px;
  text-align: justify;
  padding-top: 20px;
} */

.exp-img {
  height: 300px;
  width: 100%;
  cursor: pointer;
}

.exp-img img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  height: 100%;
  width: 100%;
}

.disclaimer-popup .modal-content {
  background: var(--bn-dark-1) !important;
  /* margin-top: -50px; */
}

.disclaimer-popup .title {
  font-weight: bold;
  margin-left: auto;
}

.disclaimer-popup .close,
.for-btn .close {
  color: var(--bn-font-1);
  font-size: 36px;
  opacity: 1;
  font-weight: normal;
  outline: 0;
}

.disclaimer-popup .message p {
  padding: 20px 0;
  text-align: center;
}

.disclaimer-popup .check-box {
  padding: 30px 0;
}

.disclaimer-popup .modal-body {
  margin-top: -30px;
}

.disclaimer-popup .modal-header {
  border-bottom: 1px solid transparent !important;
}

.btn-center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.rotate-180 {
  transform: rotate(180deg);
}

/* .front-screen{
  top: 40px !important;
 } */

/* custom drop down */

.cdropdown {
  height: fit-content;
  display: unset;
  transition: all 0.5s;
}

.cdropdown.hide {
  height: 0px;
  display: none;
  transition: all 0.5s;
}

.hide-minus {
  visibility: hidden;
  cursor: unset;
}

/* ------------------------From App.css-------------------------- */

.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--bn-font-1);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.todo-list {
  background: #e8e8e8;
  border-radius: 4px;
  padding: 5px;
  max-width: 500px;
  margin: 0 auto;
}

.todo {
  background: var(--bn-white);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  padding: 3px 10px;
  font-size: 12px;
  margin-bottom: 6px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pb-10 {
  padding-bottom: 10px;
}

.route-wrapper {
  position: relative;
}

.route-wrapper > div {
  /* position: absolute; */
}

.div-right {
  position: absolute;
  right: 10px;
}

.pill {
  border-radius: 50rem;
}

.heading_one {
  font-family: 'Kumbh Sans';
  font-weight: bold;
}

.flex-center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-around {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.flex-half span {
  flex: 0 50%;
}

.flex-triple span {
  flex: 0 33%;
}

.table-middle {
  display: table;
  margin: 0 auto;
}

/*POSITION*/

.bottom-section {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.middle-section {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.h-32 {
  height: 32px;
}

.f-grey {
  color: var(--bn-font-2);
}

.label-ticket {
  background-color: var(--bn-light);
  font-family: Kumbh Sans;
  border-radius: 25px;
  margin: 0px !important;
  padding: 10px 10px 0px 10px;
  font-weight: 700;
  display: flex;
  margin: 0px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.label-ticket h6 {
  font-size: 10px;
  font-weight: bold;
}

.label-ticket .left:before {
  content: '';
  font-size: 10px;
  border-left: 1px solid var(--bn-white);
  padding: 3px;
}

.label-ticket .right:after {
  content: '';
  padding: 3px;
}

/*TICKET HEADER*/

.ticket-header img {
  height: 188px;
  border-radius: 0.5rem;
}

.ticket-header p {
  color: var(--bn-font-2);
  /* letter-spacing: 0.5px; */
  font-weight: 500;
  font-size: 16px;
}

.ticket-header h4 {
  font-weight: bold;
  padding-top: 8px;
}

.custom-label {
  background: var(--bn-dark-2);
  border-radius: 50rem;
  padding: 0.75rem 1.5rem;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
}

/* ----------------------from fnb.css-------------------------- */

/*FNB CONFIRM*/

.fnb-confirm {
  font-family: 'Kumbh Sans';
}

.fnb-confirm .fnb-ticket {
  border-bottom: 1px solid var(--bn-border-color-1);
}

.fnb-confirm .heading {
  font-weight: bold;
  font-size: 22px;
  letter-spacing: 1.3px;
}

.fnb-confirm .mtp {
  height: 188px;
  border-radius: 0.5rem;
}

.fnb-confirm .fnb-ticket .flex-between div {
  flex: 0 50%;
  /* padding-top: .25rem; */
}

.fnb-confirm .fnb-ticket .flex-between p {
  color: var(--bn-font-2);
  font-size: 16px;
}

.fnb-confirm .fnb-ticket .flex-between h4 {
  font-weight: bold;
  padding-top: 0.5rem;
}

.fnb-confirm .fnb-total {
  background: black;
  padding: 0 1rem;
}

.fnb-confirm .fnb-total .item {
  padding-top: 15px;
}

.fnb-confirm .fnb-total .item div {
  padding: 0.5rem 0;
}

.fnb-confirm .fnb-total .item h5 {
  font-weight: normal;
}

.fnb-confirm .fnb-total .item h6 {
  font-weight: bold;
  color: var(--bn-font-1);
}

.fnb-confirm .fnb-order .flex-between div {
  flex: 0 0 33%;
}

.fnb-confirm .fnb-order .fci {
  height: 59px;
  width: 156px;
  object-fit: cover;
  object-position: top;
  border-radius: 0.5rem;
}

/* ----------------------------from page2.css------------------------ */

.parent {
  background-color: var(--bn-dark-1);
  margin-top: 220px;
  /* padding: 40px 0; */
  /* display: flex; */
  /* flex-flow: column nowrap; */
  /* justify-content: center; */
}

.page2_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.page2_movie_header {
  color: var(--bn-font-1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition-duration: 0.5s;
  cursor: pointer;
  text-decoration: none;
}

.page2_movie_header:hover {
  transform: scale(1.1);
  text-decoration: none;
  color: var(--bn-font-1);
}

.small_text {
  /* margin-top: 50px; */
  font-size: 24px;
  letter-spacing: 4px;
  margin-bottom: 50px;
  font-weight: bold;
}

.bigg_text {
  margin-top: 20px;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 5.33px;
  margin-bottom: 0;
}

.logo2 {
  height: 136px;
  width: 291px;
  cursor: pointer;
  background-image: var(--bn-logo);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
}

.choose_action {
  color: var(--bn-font-1);
  margin-top: 228px;
  margin-bottom: 100px;
  letter-spacing: 5.33px;
  font-size: 32px;
  font-weight: bold;
}

.page2_body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.huge_buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.hb {
  cursor: pointer;
  /* font-size: 32px; */
  font-size: 28px;
  letter-spacing: 2px;
  line-height: 1.2;
  font-weight: bold;
  background-color: var(--bn-brand-color);
  color: var(--bn-font-1);
  /* height: 340px; */
  height: 280px;
  /* height: clamp(200px, 33vw, 400px); */
  border: none;
  border-radius: 5px;
  /* margin: 0 20px; */
  margin: 0 16px;
  margin-bottom: 160px;
  outline: none !important;
  /* width: 340px; */
  width: 280px;
  /* width: clamp(200px, 33vw, 400px); */
  transition-duration: 0.8s;
  padding: 20px;
}

.hb:hover {
  color: var(--bn-font-1);
  transform: scale(1.05);
  background-color: var(--bn-brand-color-dark);
}

.gb {
  position: absolute;
  top: 72px;
  left: 39px;
}

/* ----------------------------------from movie.css----------------------------------- */

.main_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bn-dark-1);
  zoom: 0.8;
}

.movie_header {
  width: 100%;
  background-color: var(--bn-dark-1);
  border-bottom: 1px solid grey;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.parent .movie_header {
  position: absolute;
  top: 10px;
  right: 0px;
  padding-left: 15px;
  padding-right: 15px;
}

.movie_listings {
  /* padding: 10px 0px; */
  padding-top: 10px;
  background-color: var(--bn-dark-1);
}

.ml-col {
  display: flex;
}

.ml-col:nth-child(3n + 1) {
  justify-content: flex-start;
}

.ml-col:nth-child(3n + 2) {
  justify-content: center;
}

.ml-col:nth-child(3n + 3) {
  justify-content: flex-end;
}

.ml-col .group {
  height: 30px;
  width: 100%;
  font-size: 9.6px;
  zoom: 0.7;
}

.goback_area {
  padding: 10px 40px;
  width: 100%;
}

.go_back {
  /* width: 100%; */
  /* width: 20%; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* cursor: pointer; */
}

.button_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
  padding: 14px;
  border-radius: 50%;
  background-color: var(--bn-brand-color);
  cursor: pointer;
}

.button_wrap:hover {
  background-color: var(--bn-brand-color-dark);
}

.go_back_button {
  background-image: url('../imgs/svgs/left-arrow.svg');
  background-size: contain;
  background-repeat: no-repeat;
  height: 25px;
  width: 25px;
  background-position: 50% 50%;
}

.movie_header > .big_text {
  font-size: 24px;
  color: var(--bn-font-1);
  letter-spacing: 2px;
  font-weight: 400;
  font-weight: bold;
}

.go_back > .big_text {
  margin-left: 20px;
  margin-top: 7px;
  align-items: center;
  font-size: 20px;
  color: var(--bn-font-1);
  letter-spacing: 2px;
  font-weight: bold;
}

.movie_header > .logo {
  cursor: pointer;
  /* background-image: var(--bn-logo);
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: 70px; */
  width: 120px;
}

.movie_header > .logo img {
  width: 100%;
  height: auto;
}

.all_movies {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-flow: row wrap;
}

/* movie cards */

.card_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 90%;
  /* width: 300px; */
  width: 100%;
}

.book_ticket {
  width: 100%;
  text-align: center;
  height: 29px;
  border: none;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
  color: var(--white);
  background-color: var(--bn-brand-color);
}

.book_ticket:hover {
  background-color: var(--bn-brand-color-dark);
}

.movie_pic {
  cursor: pointer;
  border-radius: 8px;
  /* height: 400px; */
  width: 100%;
  background-color: var(--bn-brand-2);
  opacity: 0.85;
  overflow: hidden;
}

.movie_pic img {
  height: 100%;
  width: 100%;
  /* object-fit: cover; */
  /* object-position: top; */
  transition-duration: 0.8s;
}

.movie_pic img:hover {
  transform: scale(1.1);
  opacity: 1;
}

.movie_info {
  width: 100%;
  margin: 1rem 0;
  color: var(--bn-font-1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.movie_name {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  width: 50%;
}

.right_side {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.right_top {
  margin-bottom: 5px;
  font-size: 8px;
  border-radius: 20px;
  padding: 0 10px;
  height: 20px;
  background-color: var(--bn-light);
}

.right_top,
.right_bot {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.right_bot {
  font-size: 12px;
}

/* movie details page css */

.movieDetails_mainContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.movieDetails_container {
  padding-bottom: 30px;
  border-bottom: 1px solid var(--bn-border-color-1);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.thumbnail {
  border-radius: 7px;
  overflow: hidden;
  /* height: 350px;
  width: 260px; */
}

.banner {
  /* height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%; */
  width: 250px;
  height: auto;
}

.details_container {
  width: 100%;
  color: var(--bn-font-1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.details_header {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--bn-border-color-1);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_left {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.header_left > h1 {
  margin: auto 0;
  font-size: 24px;
  font-weight: bold;
}

.header_right > div {
  display: flex;
  font-size: 12px;
  letter-spacing: 1px;
  justify-content: center;
  align-items: center;
  background-color: var(--bn-dark-2);
  border-radius: 20px;
  padding: 0 5px;
}

.header_right > div > p {
  margin: 5px auto;
  padding: 0 7px;
}

.header_right .time {
  border-left: 1px solid var(--bn-border-color-1);
}

.details_body {
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.details_body .title {
  padding-bottom: 8px;
  font-size: 16px;
  color: var(--bn-font-2);
  letter-spacing: 1px;
}

.details_body .content {
  line-height: 1.2;
  letter-spacing: 1px;
  text-align: justify;
  font-size: 16px;
  font-weight: 400;
  color: var(--bn-font-1);
}

.movieFeatures_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  width: 100%;
}

.features_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.featureHeader_left > p {
  letter-spacing: 1px;
  font-size: 24px;
  color: var(--bn-font-2);
  padding-bottom: 1.5rem;
}

.featureHeader_left > h2 {
  color: var(--bn-font-1);
  margin: 0 auto;
  font-weight: bold;
  padding-top: 3.5rem;
}

.featureHeader_right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.featureHeader_right > p {
  letter-spacing: 1px;
  color: var(--bn-font-2);
  padding-bottom: 1.5rem;
  font-size: 24px;
}

.featureHeader_right_buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}

.language_button {
  color: var(--bn-font-1);
  outline: none !important;
  background-color: transparent;
  border: 2px solid white;
  width: 142px;
  height: 52px;
  vertical-align: middle;
  border-radius: 50rem;
  padding: 0.6rem 0 0.37rem 0;
  font-weight: bold;
  transition: all 0.6s;
}

.language_button.active,
.language_button:active,
.language_button:hover {
  background-color: var(--bn-brand-color);
  border: 2px solid var(--bn-brand-color);
}

.language_button:nth-child(2n-1) {
  margin-right: 1.5rem;
}

.features_body {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.features_body > p {
  font-size: 24px;
  color: var(--bn-font-2);
  margin-right: auto;
  border-bottom: 1px solid var(--bn-border-color-1);
  width: 100%;
}

.features_body > div {
  margin-top: 8px;
}

.showTimings {
  margin-top: 1.25rem;
  /* border-bottom: 1px solid var(--bn-border-color-1); */
  /* width: 100%; */
}

.time_slots {
  display: flex;
  /* justify-content: space-between; */
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}

.showTimings > h1 {
  margin-bottom: 15px;
  font-size: 24px;
  color: var(--bn-font-1);
  font-weight: bold;
}

.chooseTime_button {
  margin-right: 30px;
  margin-bottom: 15px;
  letter-spacing: 1px;
  font-weight: 500;
  color: var(--bn-font-1);
  outline: none !important;
  background-color: transparent;
  border: 1px solid white;
  width: 150px;
  height: 42px;
  border-radius: 10px;
  padding: 3px 0;
  transition-duration: 0.4s;
}

.chooseTime_button:hover {
  cursor: pointer;
  color: var(--bn-font-1);
  border: 1px solid var(--bn-brand-color);
  background-color: var(--bn-brand-color);
  /* box-shadow: 0 0 25px -10px rgb(73, 101, 255); */
}

.formatbtn {
  padding: 3px;
  font-size: 12px;
  /* margin-left: 5px;
  margin-right: 3px; */
  text-align: center;
  border-radius: 2px;
  border: 1px solid rgb(134, 133, 133);
  background-color: var(--bn-brand-color);
  color: #fff;
  width: auto;
  border-radius: 4px;
}

/*PRINT TICKET*/

.ticket-card {
  font-family: 'Kumbh Sans';
  background-color: var(--bn-brand-2) 000;
  border: 1px solid var(--bn-brand-2) 000;
}

.ticket-card .title {
  background: var(--bn-brand-color);
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 3px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
}

.ticket-header .ticket-overlay {
  position: relative;
  top: -30px;
  opacity: 0.1;
  background-color: var(--bn-brand-2);
  background-blend-mode: overlay;
}

.ticket-header .ticket-overlay img {
  height: 400px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.ticket-card .ticket-header p {
  font-weight: lighter;
  font-size: 14px;
  padding-top: 12px;
}

.ticket-header .ticket-detail {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 50px;
  /* background: url('../assets/imgs/svgs/bg-overlay.svg') no-repeat;
	background-position: bottom right;
	background-size: cover;  */
}

.ticket-order .flex-center {
  padding: 18px 0px;
}

.ticket-order h6 {
  color: var(--bn-font-2);
  font-size: 16px;
}

.ticket-order h4 {
  font-weight: bold;
}

.ticket-order .right-content {
  display: grid;
  grid-auto-flow: row;
  gap: 5%;
}

/**********DATE SLIDER IN MOVIE DETAILS PAGE********/

.date-slider {
  width: 95%;
  padding-bottom: 30px;
  zoom: 0.7;
}

.date-heading {
  font-size: 24px;
  color: var(--bn-font-2);
  text-transform: uppercase;
  letter-spacing: 2.5px;
  padding-bottom: 20px;
  width: 100%;
}

.date-box {
  text-align: center;
  /* height: 90px; */
  background-color: var(--bn-dark-2);
  /* transition: background-color 0.4s; */
  /* width: 90px !important; */
  width: 90px !important;
  border-radius: 10px;
  padding: 8px 26px 9px 27px;
  font-family: Kumbh Sans;
  transition: background-color 0.5s, color 0.5s;
}

.date-box .month-name {
  font-weight: bold;
  letter-spacing: 2.5px;
  font-size: 16px;
}

.date-box .date-no {
  font-size: 23px;
}

.date-box .day-name {
  /* color: var(--bn-font-2); */
}

.date-box:hover {
  background-color: var(--bn-brand-color);
  cursor: pointer;
}

.arrows-3 .slick-next,
.arrows-3 .slick-prev {
  top: calc(50%) !important;
  z-index: 9;
  padding: 0;
  width: 20px;
  font-size: 0;
  outline: 0;
  height: 20px;
  line-height: 0;
  cursor: pointer;
  border: none !important;
  color: #000;
  z-index: 99;
  border-radius: 0 !important;
  position: absolute;
  background-color: transparent !important;
  border-color: none !important;
}

.arrows-3 .slick-prev::after {
  left: 25px;
  content: url('../imgs/svgs/left-arrow-fill.svg');
  width: 10px;
  height: 10px;
  display: table;
  position: relative;
  -webkit-transform: unset !important;
  -ms-transform: unset !important;
  transform: unset !important;
  border-top: none !important;
  border-left: none !important;
}

.arrows-3 .slick-next::after {
  left: 22px;
  content: url('../imgs/svgs/right-arrow-fill.svg');
  width: 10px;
  height: 10px;
  display: table;
  position: relative;
  -webkit-transform: unset !important;
  -ms-transform: unset !important;
  transform: unset !important;
  border-top: none !important;
  border-left: none !important;
  opacity: 1;
}

.arrows-3 .slick-next:focus,
.arrows-3 .slick-prev:focus {
  outline: 0;
}

.arrows-3 .slick-prev {
  left: -60px;
}

.arrows-3 .slick-prev::after {
  left: 25px;
  /* content: url('../pictures/svgs/left-arrow-fill.svg'); */
  width: 10px;
  height: 10px;
  display: table;
  position: relative;
  -webkit-transform: unset !important;
  -ms-transform: unset !important;
  transform: unset !important;
  border-top: none !important;
  border-left: none !important;
}

.arrow-padding {
  padding-left: 3rem;
}

.arrows-3 .slick-next::after {
  left: 22px;
  /* content: url('../pictures/svgs/right-arrow-fill.svg'); */
  width: 10px;
  height: 10px;
  display: table;
  position: relative;
  -webkit-transform: unset !important;
  -ms-transform: unset !important;
  transform: unset !important;
  border-top: none !important;
  border-left: none !important;
}

.arrows-3 .slick-next:focus,
.arrows-3 .slick-prev:focus {
  outline: 0;
}

.arrows-3 .slick-prev {
  left: -60px;
}

.arrows-3 .slick-next {
  right: 0;
}

/* -------------------------from seat layout.css------------------------------ */

.back {
  position: absolute;
  left: 1rem;
  top: 24px;
}

.seat-title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 20px;
}

.seat-title span {
  padding: 0 1rem;
  text-align: center;
  color: var(--bn-font-1);
  letter-spacing: 5px;
  font-weight: bold;
  height: 100%;
}

.seat-title:before {
  background-color: hsla(0, 0%, 100%, 0.2);
  height: 0.8px;
  width: 100%;
  content: '';
}

.seat-title:after {
  background-color: hsla(0, 0%, 100%, 0.2);
  height: 0.8px;
  width: 100%;
  content: '';
}

.seats .cell.cell-none {
  background-color: transparent;
  cursor: unset;
  background-image: none;
}

.seats-desc img,
.seats-desc svg {
  width: 40px;
}

.seats-desc span {
  flex: 0 33%;
  padding: 20px 0px;
  font-size: 1.25rem;
  text-align: center;
}

.seats-desc {
  zoom: 0.5;
}

.paymentModeBtn {
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  background: white;
  color: black;
  font-weight: bold;
}

.cardInputBank {
  border: none;
  border-bottom: 2px solid darkgrey;
  font-size: 18px;
  width: 100px;
  color: #5f5e5e;
  margin-left: 12px;
  background: transparent;
}

.fix_height_container {
  max-height: 310px;
  overflow-y: auto;
}
.fix_height_container_2 {
  max-height: 400px;
  overflow-y: auto;
}

.rowSeatName {
  color: white !important;
  font-size: 30px;
}

.seat-footer {
  background-color: green;
  /* position: fixed;
    bottom: 0; */
}

.seat-footer {
  background-color: var(--bn-brand-2);
  position: sticky !important;
  bottom: 0;
  zoom: 0.8;
  /* padding: 30px 50px 30px 50px; */
}

.modal-4 .modal-content {
  color: var(--bn-white);
  background-color: var(--bn-dark-1) !important;
  padding: 1rem;
}

.seat-type {
  padding: 1rem;
  min-width: 230px;
  height: 100%;
  font-weight: bold;
}

.seat-type.gold {
  background-color: #141825;
  color: #f2c94c;
}

.seat-type.silver {
  background-color: #efefef;
  color: #a8a8a8;
}

.seat-type .st-name {
  font-weight: bold;
  font-size: 3rem;
  border-bottom: 1px solid #bfbfbf;
  line-height: 1;
  margin-bottom: 0.25rem;
}

.category {
  display: flex;
  flex-direction: column;
  width: 180px;
  height: 170px;
  background-color: var(--bn-dark-2);
  color: var(--bn-white);
  text-align: center;
  margin: 1rem;
  border-radius: 0.5rem;
  padding: 8px;
  line-height: 1.2;
}

.category.disabled {
  background-color: #999999;
  color: var(--bn-font-2);
}

.category.disabled .mod-btn {
  background-color: #999999;
  color: var(--bn-font-2);
}

.category .category-name {
  font-size: 1.5rem;
  font-weight: 500;
  min-height: 53px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  word-break: break-word;
}

.category .category-price {
  font-size: 1.5rem;
  font-weight: 500;
  height: 53px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.category .category-seats {
  font-weight: bold;
  font-size: 2rem;
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top: 1px solid #bfbfbf;
  padding: 0.5rem;
  width: 100%;
}

.category .category-seats .mod-btn {
  font-weight: bold;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  color: var(--bn-white);
  background-color: var(--bn-brand-color);
  height: 40px;
  width: 40px;
  border: 1px solid var(--bn-brand-color);
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.4s;
}

.category-seats > div:not(:first-child):not(:last-child) {
  padding-top: 4px;
}

.mod-disabled {
  font-weight: bold;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  background-color: var(--bn-scrollbar);
  color: var(--bn-font-2);
  height: 40px;
  width: 40px;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.4s;
}

/* .category .category-seats .mod-btn:hover {
  color: var(--bn-white);
  background-color: var(--bn-brand-color-dark);
} */

.category .category-seats .mod-btn:active {
  transform: scale(0.8);
  border-color: var(--bn-brand-color-dark);
  background-color: var(--bn-white);
  color: var(--bn-brand-color-dark);
}

.modal-90w {
  max-width: 90% !important;
}

.modal-95w {
  max-width: 95% !important;
}

.modal-100w {
  max-width: 100% !important;
}

.modal-bottom {
  position: absolute;
  bottom: 96px;
  width: 100%;
  border-top: 1px solid var(--bn-dark-2);
}

.checkout-page .imgOverlay {
  opacity: 0.3 !important;
}

.checkout-page .hero-text {
  position: absolute;
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: auto;
  background-image: none !important;
}

.checkout-page .heading {
  font-weight: bold;
  font-size: 22px;
  letter-spacing: 1.3px;
}

.checkout-footer-modal {
  position: fixed;
}

.checkout-footer-modal .form-control {
  height: 50px;
}

.grid-50 {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 40px;
  grid-row-gap: 0.5rem !important;
}

.ticket-right {
  background-color: var(--bn-brand-2);
  padding: 1.5rem !important;
}

.ticket-right .ticket-right-box .title {
  color: var(--bn-font-2);
}

.ticket-right .ticket-right-box {
  border-bottom: 1px solid var(--bn-font-2);
}

.ticket-right-box .grid-half {
  display: grid;
  grid-template-columns: 50% 50%;
  /* grid-column-gap: 50px; */
}

.ticket-right .ticket-right-box .right {
  margin-left: auto;
}
.item_name_class {
  color: #99c566 !important;
  padding-right: 4px;
  font-size: 22px;
  font-weight: bold;
}
.checkout-footer-bottom {
  background-color: var(--bn-brand-2);
  display: flex;
  grid-template-columns: 70% 30%;
  padding-top: 2em;
  padding-bottom: 2em;
  padding-left: 2em;
  padding-right: 2em;
  align-items: center;
  /* position: fixed; */
  position: sticky;
  width: 100vw;
  bottom: 0;
  margin-left: -15px;
  justify-content: space-between;
}

.cancel_btn {
  font-size: 16px;
  /* font-family: 'SansMedium'; */
  color: var(--bn-brand-color);
  padding: 10px 30px;
  cursor: pointer;
  /* text-transform: uppercase; */
  /* background-color: var(--red); */
  background-color: #fff;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid var(--bn-brand-color);
  width: 120px;
  display: flex;
  justify-content: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.cancel_btn:hover {
  border: 1px solid var(--bn-brand-color);
  background-color: var(--bn-brand-color);
  color: #fff;
}

.confirm_design_btn {
  font-size: 16px;
  /* font-family: 'SansMedium'; */
  color: #fff;
  padding: 10px 30px;
  cursor: pointer;
  /* text-transform: uppercase; */
  /* background-color: var(--red); */
  background-color: var(--bn-brand-color);
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid var(--bn-brand-color);
  width: 120px;
  display: flex;
  justify-content: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.confirm_design_btn:hover {
  border: 1px solid var(--bn-brand-color);
  background-color: #fff;
  color: var(--bn-brand-color);
}

.confirm_design_btn_wheelchair {
  font-size: 20px;
  /* font-family: 'SansMedium'; */
  color: #fff;
  padding: 20px 10px;
  cursor: pointer;
  /* text-transform: uppercase; */
  /* background-color: var(--red); */
  background-color: var(--bn-brand-color);
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid var(--bn-brand-color);
  width: 40%;
  display: flex;
  justify-content: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.confirm_design_btn_wheelchair:hover {
  border: 1px solid var(--bn-brand-color);
  background-color: #fff;
  color: var(--bn-brand-color);
}
.rating_img {
  width: 100px;
}
.wheelchair_popup_img {
  width: 100%;
}
.rating_descripton p {
  margin-bottom: 8px;
  font-size: 20px;
}
.checkout-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: var(--bn-brand-2);
}

.checkout-footer .form-control,
.checkout-footer .form-control ~ .blue-btn {
  font-size: 16px;
  height: 3em;
  margin-bottom: 0 !important;
}

.checkout-footer .form-control ~ .blue-btn {
  padding: 0;
}

.checkout-footer .title {
  color: var(--bn-font-2);
}

.checkout-footer .btn-confirm {
  padding: 20px 50px 20px 49px;
  font-size: 20px;
  letter-spacing: 03px;
}
/*Offer Accordian CSS Start*/
.accordion {
  background-color: white;
  color: #444;
  cursor: pointer;

  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  font-weight: bold;
}
.active,
.accordion:hover {
  font-weight: bold;
}
.panel {
  padding: 10px 18px;
  display: none;
  background-color: white;
  overflow: hidden;
}
.offer_txt {
  font-size: 20px;
  font-weight: bold;
}
.accordion:after {
  background: #1f1c3c;
  padding: 3px 8px;
  border-radius: 100%;
  content: '\002B';

  color: white;
  font-weight: bold;
  float: right;
}
/* .active:after {
  content: '\2212';
} */
/*Offer Accordian CSS End*/
.receipt-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  color: #000;
}

.z-index-500 {
  z-index: 500;
}

.position-absolute {
  position: absolute;
  top: 0;
  left: 0;
}

.absolute-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--bn-dark-1);
  z-index: 250;
}

.rate-card-close {
  font-size: 44px;
  /* margin-top: 32px; */
  position: absolute;
  top: -17px;
  right: -3px;
}

.checkout_modal .checkout_modal__payment_modes {
  /* display: grid; */
  /* grid-template-columns: 48% 48%; */
  /* grid-column-gap: 4%; */
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  padding: 24px;
}

.fullscreen-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
}

.fullscreen-bg__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(15px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
}

.hg-theme-default .hg-button span {
  color: #000 !important;
}

.date-box.active {
  background-color: var(--bn-brand-color);
  cursor: pointer;
}

.signage-buttons {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.activeBtn {
  background: green !important;
}

.smallTextbottom {
  font-size: 13px;
  color: lightgray;
}

.btnInactive {
  background: lightgray !important;
  pointer-events: none !important;
}

.arrows-3.arrow-disabled .slick-next::after {
  opacity: 0;
}

.spinner2 {
  height: 4rem !important;
  width: 4rem !important;
}

.hg-theme-default {
  background-color: var(--bn-brand-2);
  color: var(--bn-white);
}

.hg-theme-default .hg-button {
  background-color: var(--bn-dark-1);
  color: var(--bn-white);
  box-shadow: 0 0 3px -1px rgb(255 255 255 / 30%);
  height: 55px;
  text-transform: capitalize;
}

.hg-theme-default .hg-button span {
  background-color: transparent;
  color: var(--bn-white) !important;
}

.hg-theme-default .hg-button.hg-activeButton {
  background-color: var(--bn-white);
  color: var(--bn-dark-1);
}

.hg-theme-default .hg-button.hg-activeButton span {
  background: transparent;
  color: var(--bn-dark-1) !important;
}

.hg-theme-default .hg-button.hg-selectedButton {
  background-color: var(--bn-white);
  color: var(--bn-dark-1);
}

.hg-theme-default .hg-button.hg-selectedButton span {
  background: transparent;
  color: var(--bn-dark-1) !important;
}

.hidden-block {
  background-color: transparent;
  height: 200px;
  width: 200px;
  margin-left: auto;
  /* position: absolute; */
  position: fixed;
  right: 0;
  bottom: 0;
  /* border-radius: 50%; */
  /* margin-bottom: 160px; */
  border-radius: 5px;
}

.radio-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-group .radio {
  font-size: 20px;
  letter-spacing: 0.5px;
  position: relative;
}

.radio-group .radio input {
  margin-right: 8px;
  cursor: pointer;
  height: 1em;
  width: 1em;
  vertical-align: middle;
}

.radio-group .radio:not(:last-child) {
  margin-right: 24px;
}

.reverse.blue-btn {
  background-color: var(--bn-brand-color-dark);
}

.reverse.blue-btn:hover {
  color: var(--bn-white);
  background-color: var(--bn-brand-color);
}

.list-movie-card .content {
  /* border-bottom: 1px solid grey; */
  /* padding: 10px; */
  /* padding: 0 16px; */
  background-color: var(--bn-brand-color);
  cursor: pointer;
  transition: background-color 0.5s;
}

.list-movie-card .content:active,
.list-movie-card .content:hover {
  background-color: var(--bn-brand-color-dark);
}

.list-movie-card .content .bottom-content {
  /* position: absolute;  */
  /* bottom: 0; */
  /* right: 0; */
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  font-size: 18px;
  /* width: 60%; */
  /* margin-left: auto; */
}

.list-movie-card .content img {
  width: 200px;
}

.list-movie-card .filter-bottom {
  position: fixed;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  background: var(--bn-brand-color);

  grid-template-columns: 05% 60% 20%;
  padding: 20px 32px;
  bottom: 0px;
  align-items: center;
  box-shadow: 0 -15px 15px rgb(0 0 0 / 20%);
}

.list-movie-card .seat-occupied {
  color: darkseagreen;
  font-weight: bold;
  font-size: 22px;
}

.list-movie-card .filter-bottom .filter-tags,
.list-movie-card .filter-tags {
  display: flex;
}

.list-movie-card .filter-tags div {
  background: var(--bn-dark-1);
  color: var(--bn-white);
  padding: 14px 15px 0px;
  margin-right: 20px;
  font-size: 15px;
  border-radius: 10px;
  font-weight: bold;
  height: 45px;
  letter-spacing: 1px;
}

.list-movie-card .filter-bottom .filter-tags div {
  color: var(--bn-dark-1);
  background: var(--bn-white);
  letter-spacing: 1px;
}

.list-movie-card .grid-icons {
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
}

.list__view__movie .list__view__movie__time {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 40px;
}

.list__view__movie .list__view__movie__title {
  font-size: 2rem;
  font-weight: bold;
  color: var(--bn-dark-1);
}

.list_movie_content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding: 16px 24px;
  flex-grow: 1;
}

.list__view__movie .list__view__movie__rating {
  background-color: var(--bn-white);
  color: var(--bn-dark-1);
  height: 60px;
  min-width: 60px;
  width: 100%;
  margin: 0 auto;
  border-radius: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 0 0;
  font-size: 2rem;
  font-weight: bold;
  border: 2px solid var(--bn-dark-1);
}

.list_movie_content > div {
  display: grid;
  grid-template-columns: calc(25% - 12px) calc(75% - 12px);
  grid-gap: 24px;
}

.list_movie_content > div:nth-child(2) {
  align-items: center;
}

.list_movie_content .bottom-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
}

.list_movie_content .bottom-content > p {
  font-size: 20px;
  font-weight: bold;
  color: var(--bn-dark-1);
}

.list_movie_content .bottom-content > p:not(:last-child) {
  margin-right: 5rem !important;
}

.f-blue {
  background: #396277;
}
.f-green {
  background: #c2561a;
}

.bg-theme {
  background-color: var(--bn-brand-color);
}

.low-opacity {
  opacity: 0.55;
}

.list__view .list__view__movie:last-of-type {
  border-bottom: 1px solid grey;
  margin-bottom: 24px;
}

.__formats_and_ratings {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.__formats_and_ratings > article {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}

.__formats_and_ratings .__format,
.__formats_and_ratings .__rating {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: black;
  background-color: white;
  padding: 4px 8px 0;
  font-weight: bold;
  font-size: 18px;
  min-width: 40px;
}

.__formats_and_ratings .__rating {
  color: white;
  border: 2px solid white;
  background-color: transparent;
}

.container-fluid > .list__view__movie:first-child .day__title {
  border-top: 2px solid var(--bn-dark-1);
}

.list__view__movie .day__title,
.list__view__movie .content {
  border-bottom: 2px solid var(--bn-dark-1);
}

.day__title {
  background-color: var(--bn-white);
  color: var(--bn-dark-1);
}

.day__title h2 {
  /* height: 60px; */
  height: 80px;
  font-size: 40px;
  text-align: center;
  font-weight: bold;
  padding-top: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.___movie_rating {
  color: white;
  border: 2px solid white;
  height: 40px;
  padding: 4px 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  min-width: 40px;
}

/* ---------------------Schedules--------------------------- */

.schedules .show-btn {
  height: 48px;
  /*width: 95px;*/
  /* min-width: 95px; */
  min-width: 65px;
  width: auto;
  display: flex;
  justify-content: center;
  /* justify-content: space-between; */
  align-items: center;
  /* margin-bottom: 24px; */
  border-radius: 6px;
  color: var(--bn-dark-2);
  cursor: pointer;
  padding: 0 6px 0 12px;
  transition: background-color 0.5s, color 0.5s, border-radius 0.5s,
    border-color 0.5s, transform 0.25s;
}

.schedules .hall-name {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: right;
  border-radius: 50rem;
  background-color: var(--bn-dark-2);
  color: var(--bn-white);
  font-size: 16px;
  font-weight: 600;
  padding: 0 16px;
  /* height: 30px; */
  height: 40px;
}

.schedules .theatre-language {
  color: var(--bn-brand-color);
  text-align: right;
}

.schedules .show-btn:active {
  transform: scale(0.9);
}

.schedules .show-btn.yellow {
  background-image: none;
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

.schedules .show-btn.green {
  background-image: none;
  background-color: var(--success);
  border: 1px solid var(--success);
}

.schedules .show-btn.full {
  background-image: none;
  background-color: var(--bn-dark-1);
  color: var(--light-4);
  border: 1px solid var(--bn-dark-1);
  cursor: default;
  pointer-events: none;
}

.schedules .show-btn.yellow:hover {
  background-image: none;
  color: var(--yellow-shade);
  background-color: var(--dark-6);
  border: 1px solid var(--warning);
}

.schedules .show-btn.green:hover {
  background-image: none;
  color: var(--green-shade);
  background-color: var(--dark-6);
  border: 1px solid var(--success);
}

.schedules .show-btn .exp {
  min-width: 36px;
  width: auto;
  height: 36px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  background-color: var(--bn-dark-1);
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 700;

  padding: 0 6px;
  position: relative;
}

.schedules .show-btn .exp .hover-text {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  height: 0;
  background: var(--bn-dark-1);
  border: 3px solid var(--bn-dark-1);
  opacity: 0;
  visibility: hidden;
  transition: height 0.5s, opacity 0.5s, visibility 0s;
  border-radius: 10px;
  /* display: none; */
  bottom: 110%;
}

.schedules .show-btn .exp .hover-text p {
  flex: 0 0 50%;
  font-size: 16px;
  font-weight: 600;
  padding: 0 24px;
  text-align: center;
  color: var(--bn-white);
}

.schedules .show-btn .exp:hover .hover-text {
  width: 246px;
  height: 90px;
  opacity: 1;
  visibility: visible;
  display: flex;
}

.schedules .show-btn .exp:hover .hover-text::after {
  content: '';
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  border-top: 20px solid var(--bn-dark-1);
  position: absolute;
  top: 100%;
  right: 45%;
}

.schedules .show-btn .show-time {
  font-size: 18px;
  font-weight: 600;
}

.schedules .show-btn .seats-booked {
  font-size: 10px;
  font-weight: 600;
}

.schedules-btn {
  height: 32px;
  min-width: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  background-color: transparent;
  color: var(--light-5);
  border-radius: 8px;
  border: 1px solid var(--bn-brand-color);
  padding: 2px 12px 0;
  margin-right: clamp(8px, 1vw, 16px);
  transition: background-color 0.5s, color 0.5s, transform 0.25s;
}

.schedules-btn.__next {
  padding-top: 0;
}

.schedules-btn:focus {
  outline: none;
}

.schedules-btn:hover,
.schedules-btn:active,
.schedules-btn.selected {
  background-color: var(--bn-brand-color);
  color: var(--bn-white);
}

.schedules-btn:active {
  transform: scale(0.9);
}

.schedules {
  font-weight: 600;
}

.schedules .cinema-name {
  font-size: clamp(24px, 1.5vw, 32px);
  font-weight: bold;
  margin-bottom: 0;
}

.schedules .img-wrapper {
  margin: 0;
}

.schedules .img-wrapper img {
  width: 100%;
}

.schedules .schedules-row {
  font-size: 16px;
}

.schedules .schedules-row table {
  margin-bottom: 8px;
}

.schedules .schedules-row tr > td:nth-child(1) {
  color: var(--bn-brand-color);
}

.schedules .schedules-row tr > td:nth-child(2) {
  padding-left: 8px;
  font-weight: bold;
}

.schedules .schedules-row .play-trailer img {
  width: 24px;
}

.schedules .schedules-row:not(:last-child) {
  margin-bottom: clamp(24px, 1.6vw, 32px);
}

.schedules .schedules-row .blue-btn {
  height: 30px;
  font-size: 12px;
  padding: 0 12px;
  min-width: 70px;
}

.schedules .schedules-row .movie-title {
  font-size: 24px;
  margin-bottom: 8px;
  font-weight: bold;
}

.schedules .schedules-row .schedules-section {
  overflow-x: auto;
  margin: 0 auto;
}

.schedules .schedule-grid {
  display: grid;
  min-width: 100%;
  grid-template-columns: repeat(9, minmax(90px, 1fr));
  margin-bottom: 8px;
  font-size: 16px;
  text-align: center;
}

.schedule-grid > section {
  padding: 0 4px;
}

.schedule-grid.date-n-day > section:not(:first-child, :nth-child(2)),
.schedule-grid.schedule > section:not(:first-child) {
  border-left: 1px solid var(--bn-white);
  text-align: center;
}

.schedule-grid.date-n-day > section > p {
  word-break: break-all;
}

.schedule-grid.date-n-day > section > p:first-child {
  margin-bottom: 4px;
}

.schedules .hall-name {
  /* font-size: 12px; */
  /* height: 26px; */
  /* padding: 0 8px; */
}

.schedules .theatre-language {
  font-size: 12px;
  margin-top: 6px;
  text-align: left;
}

.schedules .show-btn {
  /* display: inline-flex; */
  /* height: 30px; */
  min-width: 40px;
  margin-bottom: 0;
  padding: 0 6px;
}

.schedules section > .show-btn:not(:last-child) {
  margin-bottom: 8px;
}

.schedules .show-btn .show-time {
  /* font-size: 12px; */
}

.schedules .show-btn .exp {
  min-width: 24px;
  height: 24px;
  margin-left: 6px;
  font-size: 10px;
  padding: 0 6px;
}

/* .schedule-grid.schedule .screen-name:hover,
.schedule-grid.schedule .screen-name:active {
  background-color: var(--dark-2);
  color: var(--light-5);
  border-color: var(--light-5);
}

.schedule-grid.schedule .screen-name:active {
  transform: scale(0.9);
} */

/* .schedules .show-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  height: 26px;
  border-radius: 50rem;
  --show-btn-theme: var(--primary);
  border: 1px solid var(--show-btn-theme);
  font-size: 12px;
  font-weight: 600;
  background-color: transparent;
  color: var(--light-5);
  margin: 0 auto;
  margin-bottom: 8px;
  cursor: pointer;
  transition: color 0.5s, transform 0.25s;
  outline: none;
}

.schedules .show-btn:active {
  transform: scale(0.9);
}

.schedules .show-btn:hover,
.schedules .show-btn:active {
  color: var(--show-btn-theme);
}

.schedules .show-btn.green {
  --show-btn-theme: var(--green-shade);
}

.schedules .show-btn.yellow {
  --show-btn-theme: var(--yellow-shade);
} */

/* ---------------------Schedules--------------------------- */

.view-icons {
  width: 40px;
  transition: transform 0.5s;
}

.view-icons:active {
  transform: scale(0.8);
}

.play-trailer {
  cursor: pointer;
  transition: transform 0.25s, font-size 0.25s, height 0.25s, width 0.25s;
}

.play-trailer img {
  /* cursor: pointer; */
  transition: transform 0.25s;
}

.play-trailer:hover {
  transform: translateY(-2.5px);
}

/* hero-section */
.cinema-hero-section {
  position: relative;
}

.cinema-hero-section .hero-img-section {
  width: 100%;
  position: relative;
  /* height: 750px; */
  height: auto;
}

.cinema-hero-section .hero-img-section .hero-img-1 {
  width: 100%;
  height: auto;
  min-height: 300px;
  /* max-height: 1000px; */
  object-fit: cover;
  object-position: center;
}

.cinema-hero-section .hero-text {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  background: linear-gradient(
    359.67deg,
    var(--dark-1) 0.29%,
    rgba(23, 22, 29, 0) 99.71%
  );
}

.cinema-hero-section .hero-text {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
}

.cinema-hero-section .hero-text > div {
  position: relative;
  /* top: 40%; */
}

.cinema-hero-section .title {
  /* font-size: 74px; */
  font-size: 32px;
  font-weight: 700;

  line-height: 1.2;
  margin-bottom: 8px;
}

.cinema-hero-section .msg {
  /* font-size: 18px; */
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 88px;
}

.cinema-hero-section .go_back {
  position: absolute;
  top: 30px;
  left: 30px;
}

.custom-check {
  width: 28px;
  height: 26px;
  border: 2px solid var(--bn-brand-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.25s;
  border-radius: 2px;
}

.custom-check:hover {
  box-shadow: 0 0 25px rgba(46, 23, 172, 0.349);
}

.custom-check:active {
  transform: scale(0.9);
}

.custom-check input {
  width: 0;
  height: 0;
}

.custom-check input + span {
  width: 100%;
  height: 100%;
  transition: opacity 0.25s, transform 0.25s;
  /* opacity: 0; */
  display: block;
  transform: scale(0);
}

.custom-check input + span::after {
  content: url(../imgs/svgs/done.svg);
}

.custom-check input:checked + span {
  /* opacity: 1; */
  transform: scale(1);
}

.select-btn {
  height: 32px;
  min-width: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  background-color: var(--bn-dark-1);
  color: var(--bn-white);
  border-radius: 8px;
  border: 1px solid var(--bn-brand-color);
  padding: 0 12px;
  margin-right: clamp(8px, 1vw, 16px);
  transition: background-color 0.5s, color 0.5s, transform 0.25s;
}

.select-btn {
  min-width: 40px;
}

.select-btn:focus {
  outline: none;
}

.select-btn:hover,
.select-btn:active,
.select-btn.selected {
  background-color: var(--bn-brand-color);
  color: var(--bn-white);
}

.select-btn:active {
  transform: scale(0.9);
}

.payment-booking-details .middle .modifier-btn-2 {
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payment-booking-details
  .middle
  .modifier-btn-2
  span:nth-child(odd):not(.inactive) {
  cursor: pointer;
}

.payment-booking-details
  .middle
  .modifier-btn-2
  span:nth-child(odd):not(.inactive):hover {
  transform: scale(1.15);
}

.payment-booking-details
  .middle
  .modifier-btn-2
  span:nth-child(odd):not(.inactive):active {
  transform: scale(0.95);
}

.payment-booking-details .middle .modifier-btn-2 span {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.payment-booking-details .middle .modifier-btn-2 > span:nth-child(odd) {
  font-size: 20px;
  border: 2px solid var(--bn-white);
  border-radius: 50%;
  color: var(--bn-white);
  height: 28px;
  width: 28px;
  flex: 0 0 28px;
  box-sizing: content-box;
  line-height: 1;
  transition: transform 0.5s, background-color 0.5s;
}

.payment-booking-details .middle .modifier-btn-2 > span:nth-child(odd):hover,
.fnb-card .fnb-content-row:last-of-type .modifier-btn-2 span:nth-child(1):active {
  color: var(--bn-brand-color);
  border-color: var(--bn-brand-color);
}

.payment-booking-details .middle .modifier-btn-2 > span:nth-child(even) input {
  margin: 0 8px;
  width: 5ch;
  background-color: transparent;
  border: 2px solid var(--bn-white);
  text-align: center;
  color: white;
  border-radius: 4px;
}

.payment-booking-details .middle .modifier-btn-2 > span:nth-child(odd).inactive {
  color: var(--bn-dark-2);
  border-color: var(--bn-dark-2);
  pointer-events: none;
  cursor: default;
}

.__rate_card_total {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--bn-white);
  padding: 12px 0 8px;
}

.__rate_card_total > h5 {
  font-size: 20px;
  margin-bottom: 0;
  line-height: 1;
}

.__rate_card_total > h4 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0;
  line-height: 1;
}

.zoom_5 {
  zoom: 0.3;
}

.zoom_5_new {
  zoom: 0.5;
}

.zoom_7 {
  zoom: 0.7;
}
.zoom_6 {
  zoom: 0.6;
}

.rating_popup_wrapper {
  color: black !important;
  zoom: 0.7;
  background: white !important;
}

.__rate_card_total.__rate_card_total__price {
  border-bottom: none;
}

.__rate_card_total.__rate_card_total__price > h4 {
  font-size: 28px;
}
.scroll-arrows-new {
  position: fixed;
  right: 50px;
  width: 55px;
  height: 55px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: var(--bn-brand-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  padding: 24px;
  border: none;
  z-index: 1;
  transition: background-color 0.5s, transform 0.25s;
}
.responsive-wrapping {
  flex-wrap: wrap;
}
@media (min-width: 990px) and (max-width: 1200px) {
  .responsive-wrapping {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr) !important;
    /* flex-wrap: wrap; */
  }
}
@media (max-width: 768px) {
  .choose_action {
    margin-top: 60px;
    font-size: 24px;
    text-align: center;
  }
  .login_form .msg {
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: 35px;
  }
}
